import { useEffect } from 'react';

const URL_PARAMETERS = [
  'utm_campaign',
  'utm_medium',
  'utm_source',
  'utm_content',
  'gclid',
];

/**
 * Store URL parameters such as UTMs into session storage
 */
export const useParametersStorage = () => {
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location?.search &&
      typeof window.sessionStorage?.setItem === 'function'
    ) {
      const urlParameters = new URLSearchParams(window.location.search);

      for (const key of URL_PARAMETERS) {
        const value = urlParameters.get(key);

        console.log('key', key, 'value', value);

        if (value) {
          window.sessionStorage.setItem(key, value);
        }
      }
    }
  }, []);
};
